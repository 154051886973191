import PropTypes from 'prop-types';
import { logger } from '@activebrands/core-web/utils/logger';
import loadable from '@loadable/component';
import ErrorCatcher from 'components/ErrorCatcher';

const availableModules = {
    accountShortcutsModule: loadable(() => import('libs/storyblok/content/Modules/AccountShortcutsModule')),
    actRewardsSummaryModule: loadable(() =>
        import('@activebrands/core-web/components/modules/ActRewardsSummaryModule')
    ),
    articlePromotionModule: loadable(() => import('@activebrands/core-web/components/modules/ArticlePromotionModule')),
    backgroundMediaModule: loadable(() => import('@activebrands/core-web/components/modules/BackgroundMediaModule')),
    callToActionModule: loadable(() => import('@activebrands/core-web/components/modules/CallToActionModule')),
    categoryModule: loadable(() => import('@activebrands/core-web/components/modules/CategoryModule')),
    discoverModule: loadable(() => import('@activebrands/core-web/components/modules/DiscoverModule')),
    featurePromotionModule: loadable(() => import('@activebrands/core-web/components/modules/FeaturePromotionModule')),
    flexibleModule: loadable(() => import('@activebrands/core-web/components/modules/FlexibleModule')),
    fourColumnsModule: loadable(() => import('libs/storyblok/content/Modules/FourColumnsModule')),
    informationModule: loadable(() => import('@activebrands/core-web/components/modules/InformationModule')),
    loopingTextBannerModule: loadable(() => import('libs/storyblok/content/Modules/LoopingTextBannerModule')),
    newsletterModule: loadable(() => import('libs/storyblok/content/Modules/NewsletterModule')),
    productPromotionModule: loadable(() => import('libs/storyblok/content/Modules/ProductPromotionModule')),
    shopTheLookModule: loadable(() => import('@activebrands/core-web/components/modules/ShopTheLookModule')),
    textModule: loadable(() => import('@activebrands/core-web/components/modules/TextModule')),
    threeColumnsModule: loadable(() => import('libs/storyblok/content/Modules/ThreeColumnsModule')),
    twoColumnsModule: loadable(() => import('libs/storyblok/content/Modules/TwoColumnsModule')),
    videoPlayerModule: loadable(() => import('@activebrands/core-web/components/modules/VideoPlayerModule')),
};

const Modules = ({ modules = [], ...rest }) => {
    if (modules && modules.length > 0) {
        return modules.map((moduleData, i) => {
            // See if the componentName exists inside availableContent.
            const componentName = moduleData.component || null;
            const ContentComponent = availableModules[componentName] || null;

            if (ContentComponent) {
                return (
                    <ErrorCatcher key={moduleData._uid}>
                        <ContentComponent content={moduleData} index={i} layout={componentName} {...rest} />
                    </ErrorCatcher>
                );
            }

            logger.warn(`ContentComponent ${componentName} not found.`);
            return null;
        });
    }
    return null;
};

Modules.propTypes = {
    modules: PropTypes.arrayOf(
        PropTypes.shape({
            component: PropTypes.string,
            _uid: PropTypes.string,
        })
    ),
};

export default Modules;
