import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import Link from '@activebrands/core-web/components/Link';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import getPositionStyles from '@activebrands/core-web/utils/style-functions/get-position-styles';
import getTextColorStyles from '@activebrands/core-web/utils/style-functions/get-text-color-styles';
import ButtonsList from 'components/buttons/ButtonsList';
import ThemeButton from 'components/buttons/ThemeButton';
import PlayIcon from 'components/icons/PlayIcon';
import Heading from 'components/text/Heading';
import Tag from 'components/text/Tag';

const Wrapper = styled('div', {
    position: 'relative',
    height: '100%',
    margin: '0 auto',
});

const ContentWrapper = styled('div', {
    position: 'relative',
    height: '100%',
    padding: ['24px 16px', null, null, null, null, '24px'],
});

const ContentBox = styled('div', {
    width: '100%',
    position: 'relative',
    alignContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    textAlign: 'inherit',
    zIndex: 1,
});

const MaxWidthWrapper = styled('div', {
    height: '100%',
    margin: '0 auto',
    position: 'relative',
    width: '100%',
});

const Flexible = ({
    background,
    backgroundLoading = 'eager',
    backgroundOverlay,
    buttons = [],
    contentHorizontalAlignment = 'center',
    contentVerticalAlignment = 'center',
    heading,
    headingStyle = 'md',
    headingType = 'h2',
    id,
    isHero,
    layout = 'oneColumn',
    playButtonCallback,
    playButtonLabel,
    tag = '',
    text,
    textColor = 'black',
    wrapperLink,
}) => {
    const [css] = useStyletron();
    const hasButtons = buttons.length > 0;

    const headingFontKeys = {
        sm: ['Primary/32_100_-2', null, null, 'Primary/40_110_-2'],
        md: ['Primary/40_110_-2', null, null, 'Primary/64_100_-4'],
    };

    const buttonListStyling = {
        oneColumn: { flexWrap: 'wrap' },
        twoColumns: { flexWrap: 'wrap', flexDirection: ['row', null, 'column', null, 'row'], alignItems: 'flex-start' },
        threeColumns: { flexDirection: 'column' },
    };

    const contentButtonStyling = {
        black: 'inverted',
        white: 'default',
    };

    const textColorStyle = getTextColorStyles(textColor);
    const positionStyles = getPositionStyles({ contentHorizontalAlignment, contentVerticalAlignment });
    const buttonListStyles = buttonListStyling[layout];
    const isOneColumn = layout === 'oneColumn';
    const isThreeColumns = layout === 'threeColumns';

    return (
        <Wrapper
            $style={{
                width: isHero || !isOneColumn ? '100%' : 'calc(100% - var(--margin-content) * 2)',
            }}
            id={id}
        >
            <Link className={css(textColorStyle)} to={wrapperLink}>
                <BackgroundDynamic
                    background={background}
                    backgroundOverlay={backgroundOverlay}
                    loading={backgroundLoading}
                />
                <ContentWrapper $style={{ textAlign: contentHorizontalAlignment, ...textColorStyle }}>
                    {tag && (
                        <div
                            className={css({
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                backgroundColor: 'var(--color-bg-flexible-tag)',
                                height: '24px',
                                color: 'var(--color-text-tag)',
                            })}
                        >
                            <Tag>{tag}</Tag>
                        </div>
                    )}
                    <MaxWidthWrapper $style={positionStyles}>
                        <ContentBox>
                            {playButtonCallback && (
                                <div>
                                    <ThemeButton
                                        $style={{
                                            display: 'flex',
                                            gap: '0 8px',
                                            alignItems: 'center',
                                            padding: '12px',
                                        }}
                                        fontKeys="Primary/16_100_-05"
                                        theme={contentButtonStyling[textColor]}
                                        onClick={playButtonCallback}
                                    >
                                        {playButtonLabel}
                                        <PlayIcon />
                                    </ThemeButton>
                                </div>
                            )}
                            {heading && (
                                <Heading as={headingType} fontKeys={headingFontKeys[headingStyle]}>
                                    {heading}
                                </Heading>
                            )}
                            {text && (
                                <div className={css({ ...positionStyles })}>
                                    <div
                                        className={css({
                                            ...textStyles['Primary/16_140_-05'],
                                            width: ['100%', null, '80%', null, null, '66%'],
                                            margin: contentHorizontalAlignment === 'center' ? '0 auto' : 'unset',
                                            display: 'inline-flex',
                                        })}
                                    >
                                        <RichText data={text} />
                                    </div>
                                </div>
                            )}
                            {hasButtons && (
                                <ButtonsList
                                    buttonStyling={{ width: isThreeColumns ? '100%' : 'auto' }}
                                    $style={{
                                        gap: '16px',
                                        ...positionStyles,
                                        ...buttonListStyles,
                                    }}
                                    buttons={buttons}
                                />
                            )}
                        </ContentBox>
                    </MaxWidthWrapper>
                </ContentWrapper>
            </Link>
        </Wrapper>
    );
};

Flexible.propTypes = {
    background: PropTypes.arrayOf(PropTypes.object),
    backgroundLoading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    contentHorizontalAlignment: PropTypes.oneOf(['left', 'right', 'center']),
    contentVerticalAlignment: PropTypes.oneOf(['top', 'bottom', 'center']),
    heading: PropTypes.string,
    headingStyle: PropTypes.oneOf(['sm', 'md']),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    isHero: PropTypes.bool,
    layout: PropTypes.oneOf(['oneColumn', 'twoColumns', 'threeColumns']),
    playButtonCallback: PropTypes.func,
    playButtonLabel: PropTypes.string,
    tag: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    textColor: PropTypes.string,
    wrapperLink: PropTypes.string,
};

export default Flexible;
