import { useState } from 'react';
import PropTypes from 'prop-types';
import { getButtonTheme, themes } from 'config/branding/component-themes/button-themes';
import textStyles from 'config/branding/textStyles';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';
import AddToBasketIcon from 'components/icons/AddToBasketIcon';
import AddedToBasketIcon from 'components/icons/AddedToBasketIcon';
import ArrowIcon from 'components/icons/ArrowIcon';
import BellIcon from 'components/icons/BellIcon';
import CloseIcon from 'components/icons/CloseIcon';
import EditIcon from 'components/icons/EditIcon';
import LoadingIcon from 'components/icons/LoadingIcon';
import PlusIcon from 'components/icons/PlusIcon';

const icons = {
    arrowIcon: ArrowIcon,
    default: AddToBasketIcon,
    edit: EditIcon,
    error: CloseIcon,
    loading: LoadingIcon,
    notifyMe: BellIcon,
    plusIcon: PlusIcon,
    success: AddedToBasketIcon,
};

const StateButton = ({
    $iconStyle = {},
    $lineStyle = {},
    $stateTextStyle = {},
    $style = {},
    active = false,
    children = null,
    disabled = false,
    extraText = '',
    fontKeys = '',
    hasIcon = true,
    iconPosition = 'right',
    iconSize = '16px',
    onClick = () => {},
    state = 'arrowIcon',
    statesTexts = {},
    theme = 'default',
    ...rest
}) => {
    const [css] = useStyletron();
    const currentStateText = statesTexts[state] || children;
    const IconComponent = icons[state] || null;
    const [hover, setHover] = useState(false);

    const { iconColor, underlineColor, activeStyles, hoverStyles, disabledStyles } =
        themes[getButtonTheme(theme)] || {};

    const getIconColor = () => {
        switch (true) {
            case disabled:
                return disabledStyles?.iconColor || iconColor;
            case active:
                return activeStyles?.iconColor || iconColor;
            case hover:
                return hoverStyles?.iconColor || iconColor;
            default:
                return iconColor;
        }
    };

    const getUnderlineColor = () => {
        switch (true) {
            case disabled:
                return disabledStyles?.underlineColor || underlineColor;
            case active:
                return activeStyles?.underlineColor || underlineColor;
            case hover:
                return hoverStyles?.underlineColor || underlineColor;
            default:
                return underlineColor;
        }
    };

    return (
        <ThemeButton
            active={active}
            disabled={disabled}
            theme={theme}
            $style={{
                alignItems: 'center',
                display: 'flex',
                gap: '16px',
                justifyContent: 'space-between',
                ...textStyles[fontKeys],
                ...$style,
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={onClick}
            {...rest}
        >
            {(extraText || hasIcon) && (
                <div
                    className={css({
                        alignItems: 'center',
                        display: 'flex',
                        gap: '0 24px',
                        justifyContent: 'space-between',
                        order: iconPosition === 'left' ? 1 : 2,
                    })}
                >
                    {extraText && (
                        <span
                            className={css({
                                order: iconPosition === 'left' ? 2 : 1,
                                ...textStyles['Primary/12_100_-1'],
                            })}
                        >
                            {extraText}
                        </span>
                    )}
                    {IconComponent !== null && (
                        <IconComponent
                            $style={{
                                order: iconPosition === 'left' ? 1 : 2,
                                transform: state === 'arrowIcon' && iconPosition === 'left' ? 'rotate(180deg)' : null,
                                ...$iconStyle,
                            }}
                            size={iconSize}
                            state={state}
                            color={getIconColor()}
                        />
                    )}
                </div>
            )}
            <div
                className={css({
                    order: iconPosition === 'left' ? 2 : 1,
                    position: 'relative',
                    ':before': {
                        content: "' '",
                        display: 'block',
                        position: 'absolute',
                        width: '100%',
                        height: '1px',
                        backgroundColor: getUnderlineColor(),
                        transition: 'background-color var(--transition-fast)',
                        bottom: '-2px',
                        left: 0,
                        ...$lineStyle,
                    },
                    ...$stateTextStyle,
                })}
            >
                {currentStateText}
            </div>
        </ThemeButton>
    );
};

StateButton.propTypes = {
    $iconStyle: PropTypes.object,
    $lineStyle: PropTypes.object,
    $stateTextStyle: PropTypes.object,
    $style: PropTypes.object,
    active: PropTypes.bool,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    extraText: PropTypes.string,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    hasIcon: PropTypes.bool,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    iconSize: PropTypes.string,
    onClick: PropTypes.func,
    state: PropTypes.string,
    statesTexts: PropTypes.shape({
        default: PropTypes.string,
        error: PropTypes.string,
        loading: PropTypes.string,
        reset: PropTypes.string,
        success: PropTypes.string,
    }),
    theme: PropTypes.string,
};

export default StateButton;
