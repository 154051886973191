import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import media from '@activebrands/core-web/config/media';
import { styled } from '@activebrands/core-web/libs/styletron';
import InputErrors from 'components/Form/InputErrors';
import StateIcon from 'components/icons/StateIcon';
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';
import Input from '../Input';
import CallingCodeSelect from './CallingCodeSelect';

const Wrapper = styled('div', {
    position: 'relative',
});

const InputWrapper = styled('div', {
    position: 'relative',
});

const CallingCodeSelectToggle = styled('div', {
    position: 'absolute',
    top: '50%',
    zIndex: 5,
    left: '8px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    ...textStyles['Primary/16_100_-05'],

    [media.min['desktop.sm']]: {
        zIndex: 10,
    },
});

const Description = styled('span', {
    marginTop: '4px',
});

const PhoneInput = ({
    $style = {},
    ariaLabel = '',
    errors = [],
    description,
    isPhoneNumberField = true,
    userCountry,
    ...rest
}) => {
    const countries = useSelector(state => state.ecommerce.countries);
    const ref = useRef();
    const selectRef = useRef();

    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState(userCountry);
    const [userCallingCode, setUserCallingCode] = useState(`+${getCountryCallingCode(userCountry)}`);
    const [selectWidth, setSelectWidth] = useState();

    useEffect(() => {
        setSelectedRegion(userCountry);
        setUserCallingCode(`+${getCountryCallingCode(userCountry)}`);
    }, [userCountry]);

    useEffect(() => {
        if (selectRef.current) {
            setSelectWidth(selectRef.current.getBoundingClientRect().width);
        }
    });

    // Maps through supported regions & adds respective country.
    // Filters out the few countries that don't match a country code, countries that are prioritized & sorts alphabetically
    const countriesAndCountryCodes = getCountries()
        .map(region => {
            const callingCode = `+${getCountryCallingCode(region)}`;
            const country = Object.values(countries).filter(country => country.id === region);

            return { isoCode: region, callingCode, country: country[0]?.name };
        })
        .filter(region => region?.country)
        .sort((a, b) => {
            if (a.country < b.country) {
                return -1;
            }
            if (a.country > b.country) {
                return 1;
            }

            return 0;
        });

    const handleClick = (region, callingCode) => {
        setIsSelectOpen(false);
        setSelectedRegion(region);
        setUserCallingCode(callingCode);
    };

    const inputBorderStyles = isSelectOpen
        ? {
              borderBottom: ['1px solid var(--color-border)', null, null, null, null, 'none'],
              borderRadius: ['8px', null, null, null, null, '2px 2px 0 0'],
          }
        : {};

    if (!isPhoneNumberField) {
        return <Input {...rest} $style={$style} errors={errors} />;
    }

    return (
        <Wrapper $style={$style}>
            <InputWrapper>
                <CallingCodeSelectToggle ref={selectRef} onClick={() => setIsSelectOpen(!isSelectOpen)}>
                    <StateIcon
                        $style={{ marginRight: '8px' }}
                        size="10px"
                        state={isSelectOpen ? 'chevron-up' : 'chevron-down'}
                    />
                    {userCallingCode}
                </CallingCodeSelectToggle>
                <Input
                    {...rest}
                    aria-label={ariaLabel}
                    data-locale={selectedRegion}
                    data-type="phoneNumber"
                    inputStyle={{
                        height: '32px',
                        ':focus + label': {
                            display: 'none',
                        },
                        ':not(:placeholder-shown) + label': {
                            display: 'none',
                        },
                        paddingLeft: `calc(${selectWidth}px + 11px)`,
                        marginTop: '2px',
                        ...inputBorderStyles,
                    }}
                />
            </InputWrapper>
            <CallingCodeSelect
                callingCodes={countriesAndCountryCodes}
                handleClick={handleClick}
                isSelectOpen={isSelectOpen}
                mobileSelectWidth={selectWidth}
                ref={ref}
            />
            {description && <Description>{description}</Description>}
            {errors.length > 0 && <InputErrors errors={errors} />}
        </Wrapper>
    );
};

PhoneInput.propTypes = {
    $style: PropTypes.object,
    ariaLabel: PropTypes.string,
    description: PropTypes.string,
    errors: PropTypes.array,
    isPhoneNumberField: PropTypes.bool,
    userCountry: PropTypes.string,
};

export default PhoneInput;
