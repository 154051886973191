import PropTypes from 'prop-types';
import { getButtonTheme, sizes, themes } from 'config/branding/component-themes/button-themes';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';
import Button from 'components/buttons/Button';

const ThemeButton = ({ $style = {}, fontKeys = 'Primary/18_100_-3', size = 'md', theme = 'default', ...rest }) => {
    const textStyles = responsiveTextStyles(fontKeys);

    return (
        <Button
            $style={{
                display: 'inline-flex',
                borderStyle: 'solid',
                borderWidth: '1px',
                textAlign: 'center',
                transitionDuration: 'var(--duration-fast)',
                transitionProperty: 'all',
                transitionTimingFunction: 'var(--ease)',
                ...textStyles,
                ...$style,
            }}
            availableSizes={sizes}
            size={size}
            theme={themes[getButtonTheme(theme)]}
            {...rest}
        />
    );
};

ThemeButton.propTypes = {
    $style: PropTypes.object,
    fontKeys: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    theme: PropTypes.oneOf([
        'default',
        'defaultsecondary',
        'inverted',
        'link',
        'navigation',
        'none',
        'orange',
        'outlined',
        'pagination',
        'white_outlined',
        'white',
    ]),
};

export default ThemeButton;
